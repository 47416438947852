.App {
  text-align: center;
}


/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

.header {
  /* display: flex; */
}

.App-logo {
  height: 300px;
  pointer-events: none;
}

.lang-and-menu {
  display: flex;
  justify-content: space-between;
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.menu_item {
  margin-left: 17px;
  color: rgb(90, 90, 123);
  font-size: 25px;
}

.languages{
  display: flex;
  justify-content: flex-end;
}
